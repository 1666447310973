.App {
  padding: 0;
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #BA9A83;
  font-family: "Nunito", sans-serif;
  font-size: 1.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App a {
  color: antiquewhite;
  text-decoration: none;
}

header {
  width: 100%;
  height: 160px;
  background-color: #12061E;
  box-shadow: 5px 0px 15px #58524A;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: antiquewhite;
  position: relative;
  z-index: 10;
}

.header-title {
  font-weight: 700;
  font-size: 26px;
  margin-right: auto;
  margin-left: 20px;
}

.header-list {
  list-style-type: none;
  display: flex;
  padding: 10px;
}

.header-list > li {
  margin: 5px;
}

.header-img {
  height: 100%;
}

main {
  padding: 20px 30px;
}

main > p {
  padding: 0;
  margin: 0;
}

main > ul {
  margin: 20px 0;
}

main > ul > li::marker {
  font-size: 1.2em;
  color: antiquewhite;
}

.content-header {
  margin: 20px 0;
  font-weight: 700;
  font-size: 28px;
}

.content-subheader {
  margin: 30px 0 20px 0;
  font-weight: 700;
  font-size: 22px;
}

.memeImage {
  height: 300px;
}

.content-monnikers {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 10px 10px 20px 10px;
}

.content-form {
  width: 600px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.content-textbox {
  display: block;
  border: none;
  font-family: "Nunito", sans-serif;
  font-size: 1em;
  padding: 10px;
  margin-top: 20px;
  background-color: wheat;
  border-radius: 3px;
}

.content-form input[type=checkbox] {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.content-form label {
  margin: 20px 0 0 20px;
  vertical-align: middle;
  display: inline-block;
}

.content-button {
  border: none;
  width: 40%;
  font-family: "Nunito", sans-serif;
  font-size: 1em;
  padding: 10px;
  margin: 20px 10px 0 0;
  background-color: #58524A;
  color: wheat;
  border-radius: 3px;
  cursor: pointer;
}

.content-status {
  display: inline-block;
  padding-top: 10px;
}

.moniker-card {
  padding: 20px;
  margin: 16px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #58524A;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #58524A;
  position: relative;
}

.moniker-card-hidden {
  background-image:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNHB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDQgNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNCA0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0FCQUJBQiIgc3Ryb2tlLXdpZHRoPSIwLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9IjAiIHkxPSIwIiB4Mj0iNCIgeTI9IjQiLz4NCjwvc3ZnPg0K");
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 20px 20px;
}

.moniker-card:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.moniker-card a {
  color: #000
}

.moniker-badge {
  position: absolute;
  bottom: 6px;
  right: 6px;
  background-color: wheat;
  padding: 3px 5px;
  border-radius: 3px;
  font-weight: 700;
}

.moniker-show {
  position: absolute;
  top: 1px;
  right: 3px;
  padding: 3px 5px;
  font-weight: 700;
  cursor: pointer;
}

.moniker-vote {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin: 10px 0;
}

.moniker-updating {
  font-size: x-small;
  position: absolute;
  bottom: 6px;
  left: 6px;
}

.moniker-error {
  font-size: x-small;
  position: absolute;
  color: brown;
  bottom: 6px;
  left: 6px;
}

.moniker-vote > button {
  margin: 0 10px;
  border: none;;
  padding: 10px;
  font-size: 1em;
  border-radius: 25%;
  background-color: #58524A;
  color: wheat;
  cursor: pointer;
}

.moniker-name {
  font-weight: 700;
}

.footer {
  margin-top: auto;
  padding: 10px;
  background-color: #12061E;
  color: antiquewhite;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
}

.login-textbox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.login-google {
  background-color: #4285f4;
}

.login div {
  margin-bottom: 10px;
}